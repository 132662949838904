<template>
  <div class="container">
    <PublicHeader :totalName="$t('Withdrawal').title" />
    <div class="top box">
      <div class="line" @click="withTypeShow = true">
        <span>{{ $t('Withdrawal').withType }}</span>
        <span>{{ withTypeName }}</span>
        <van-icon name="arrow-down" />
      </div>
      <div v-if="userInfo.IsBindBank&&form.TransferType == 'Bank'">
        <div v-if="basicConfig.IsBank" class="line">
          <span>{{ $t('Withdrawal').cardNo }}</span>
          <span>{{ showBankCard(userInfo.CardNo) }}</span>
        </div>
        <div v-else class="line">
          <span>SWIFTCode</span>
          <span>{{ showBankCard(userInfo.SWIFTCode) }}</span>
        </div>
      </div>

      <router-link tag="div" :to="{ name: bindRouter }" class="line unBank" v-if="!userInfo.IsBindBank&&form.TransferType == 'Bank'">
        <span>{{
          basicConfig.IsBank
            ? $t('Withdrawal').unBind
            : $t('Withdrawal').unSWIFTCode
        }}</span>
        <van-icon name="debit-pay" size="20" style="margin-left: 8px" />
      </router-link>
      <div v-if="basicConfig.IsShowCurrency">
        <div class="line" v-if="userInfo.USDT&&form.TransferType == 'USDT'">
          <span>USDT</span>
          <span>{{ showBankCard(userInfo.USDT) }}</span>
        </div>
        <div class="line" v-if="userInfo.BTC&&form.TransferType == 'BTC'">
          <span>BTC</span>
          <span>{{ showBankCard(userInfo.BTC) }}</span>
        </div>
        <div class="line" v-if="userInfo.ETH&&form.TransferType == 'ETH'">
          <span>ETH</span>
          <span>{{ showBankCard(userInfo.ETH) }}</span>
        </div>
      </div>

      <div class="line noBottomLine">
        <span>{{ $t('Withdrawal').AccountBalance }}</span>
        <span>{{ basicConfig.Symbol }}{{ userInfo.Balance }}</span>
      </div>
      <div v-if="basicConfig.IsFrozen">
        <div class="line">
          <span>{{ $t('Withdrawal').cashBalance }}</span>
          <span>{{ basicConfig.Symbol }}{{ userInfo.CashAmount }}</span>
        </div>
        <p class="info" v-if="userInfo.FrozenAmount > 0">
          {{ $t('Withdrawal').RrozenAmount }}：{{ basicConfig.Symbol }}
          {{ userInfo.FrozenAmount }}
        </p>
      </div>

      <div v-if="basicConfig.IsCashRealName">
        <div class="line" v-if="!userInfo.IsReal">
          <span>{{ $t('Withdrawal').PRNA }}</span>
          <router-link tag="span" to="Authentication" class="authentication">{{
            $t('Withdrawal').RNA
          }}</router-link>
        </div>
        <div v-else>
          <div class="line">
            <span>{{ $t('Withdrawal').RealName }}</span>
            <span>{{ userInfo.RealName }}</span>
          </div>
          <div class="line">
            <span>{{ $t('Withdrawal').mobile }}</span>
            <span>***********</span>
          </div>
          <div class="line">
            <span>{{ $t('Withdrawal').IDNumber }}</span>
            <span>***********</span>
          </div>
          <div class="line">
            <span>{{ $t('Withdrawal').CertificateAddress }}</span>
            <span>***********</span>
          </div>
          <div class="line">
            <span>{{ $t('Withdrawal').CurrentAddress }}</span>
            <span>***********</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom box">
      <div class="money">
        <div class="line">
          <span>{{ $t('Withdrawal').WithdrawAmount }}</span>
          <van-field v-model="form.Amount" input-align="right" @input="handleChange" :placeholder="placeholder" />
        </div>
        <p>
          <!-- {{ $t('Withdrawal').placeholder1 }}{{ userInfo.Charge }}
          {{ $t('Withdrawal').placeholder2 }}
          {{ basicConfig.Symbol
          }}{{ ((userInfo.Charge / 100) * form.Amount).toFixed(2) }} -->
          {{ $t('Withdrawal').CommissionText }} {{ basicConfig.Symbol
          }}{{ realAmount.toFixed(2) }}
        </p>
      </div>
      <div class="line">
        <span>{{ $t('Withdrawal').WithdrawPassword }}</span>
        <van-field v-model="form.CashPassword" type="password" input-align="right"
          :placeholder="$t('Withdrawal').placeholder" />
      </div>
    </div>
    <div class="bigBtn" @click="handleSubmit">
      {{ $t('Withdrawal').Submit }}
    </div>
    <van-popup v-model="withTypeShow" position="bottom">
      <van-picker :title="$t('public').select" :confirm-button-text="$t('public').confirm"
        :cancel-button-text="$t('public').cancel" show-toolbar :default-index="defaultIndex" value-key="Name"
        :columns="withTypeArr" @confirm="onConfirm" @cancel="withTypeShow = false" />
    </van-popup>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import userApi from '@/api/user'
import transactionApi from '@/api/transaction'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      withTypeShow: false,
      withTypeName: this.$t('Withdrawal').bankCard,
      withTypeArr: [{ Id: 'Bank', Name: this.$t('Withdrawal').bankCard }, { Id: 'USDT', Name: 'USDT' }, { Id: 'BTC', Name: 'BTC' }, { Id: 'ETH', Name: 'ETH' }],
      defaultIndex: 0,
      userInfo: {},
      placeholder: '',
      realAmount: 0,
      form: {
        CashPassword: null,
        IsUpper: false,
        Amount: null,
        TransferType: 'Bank'
      },
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
    bindRouter() {
      return this.basicConfig.IsBank ? 'Bank' : 'SWIFTCode'
    },
  },
  methods: {
    onConfirm(value) {
      this.withTypeName = value.Name
      this.form.TransferType = value.Id
      this.withTypeShow = false
    },
    handleChange() {
      let form = this.form
      form.Amount = form.Amount.replace(/[^\d.]/g, '')
      if (this.timer) {
        clearTimeout(this.timer)
        this.getCharge()
      } else {
        this.getCharge()
      }
    },
    getCharge() {
      this.timer = setTimeout(async () => {
        if (Number(this.userInfo.Balance) >= Number(this.form.Amount)) {
          const res = await transactionApi.charge({
            Amount: Number(this.form.Amount),
          })
          this.realAmount = this.form.Amount - res
        } else {
          this.realAmount = 0
        }
      }, 1000)
    },
    async getUserInfo() {
      let res = await userApi.userInfo()
      this.userInfo = res
      this.placeholder =
        this.basicConfig.Symbol +
        res.CashLowLimit +
        ' - ' +
        this.basicConfig.Symbol +
        res.CashTopLimit
      if (!res.IsCashPassword) {
        this.$dialog
          .confirm({
            title: this.$t('Withdrawal').tips,
            message: this.$t('Withdrawal').tipsMessage,
          })
          .then(() => {
            this.$router.push({ name: 'EditWithPassword' })
          })
          .catch(() => {
            this.$router.go(-1)
          })
      }
    },
    showMobile(PhoneNumber) {
      return PhoneNumber.substr(0, 3) + '****' + PhoneNumber.substr(8)
    },
    showIdCard(IdCard) {
      return IdCard.substr(0, 5) + '*********' + IdCard.substr(-4)
    },
    showBankCard(CardNo) {
      return '**** **** **** ' + CardNo.substr(-4)
    },
    async handleSubmit() {
      let form = { ...this.form }
      if (form.Amount === '') {
        this.$toast({
          message: this.$t('Withdrawal').AmountMessage,
          position: 'bottom',
        })
        return
      }
      if (form.CashPassword.length < 6) {
        this.$toast({
          message: this.$t('Withdrawal').PassMessage,
          position: 'bottom',
        })
        return
      }
      form.Amount = Number(form.Amount)
      await transactionApi.TransferRecords(form)
      this.$toast.success(this.$t('public').SubmittedSuccessfully)
      if (this.basicConfig.IsCashToService) {
        this.$router.push({
          name: 'WithService',
          params: { Amount: form.Amount },
        })
      } else {
        this.$router.go(-1)
      }
    },
  },
  mounted() {
    this.getUserInfo()
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size: 14px;
  color #ebe5cd
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  .info
    text-align right
    color #e8d49e
    height 21px
    font-size 12px
    padding-right 10px
  .box
    border-radius 5px
    border 1Px solid #40404b
    margin 15px 14px
    .line
      height 40px
      width 100%
      flex-between()
      padding 0 10px
      border-bottom 1Px solid #40404b
      span:nth-of-type(2)
        color #fff
      .authentication
        text-decoration underline
    .noBottomLine
      border-bottom none
    .unBank
      justify-content flex-start
  .bottom
    .line
      border-bottom none
      .van-field
        width 50%
        background none
        padding 0
        >>> .van-field__control
          color #fff
        >>> input::-webkit-input-placeholder
          color #969799
    .money
      border-bottom 1Px solid #40404b
      p
        text-align right
        color #e8d49e
        height 21px
        font-size 12px
        padding-right 10px
  .bigBtn
    width: 92%
    height 40px
    flex-center()
    color #222222
    font-size: 15px
    background: linear-gradient(315deg,#e8d49e,#d6b372)!important;
    margin 40px auto
    border-radius: 5px
.tab{
  display: flex
  margin: 10px 20px;
  justify-content: space-between;
  align-items: center;
}
</style>
